@import "../../assets/scss/variables.scss";

.auth-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;

  .header-layout {
    width: 100%;
    z-index: 2;
  }

  .main-layout {
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  .sidebar-layout {
    width: 450px;
    height: calc(100vh - 70px);
    position: sticky;
    top: 70px;
    z-index: 1;
  }

  .content-layout {
    flex: 1;
    padding: 0 !important;
    background-color: #f3f6f6;
    margin-top: 65px;
  }
}

.no-auth-layout {
  margin: 0;
  padding: 0;
}

.success-payment-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  .okay-icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: rgb(80, 184, 72);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .auth-layout {
    display: unset;
    position: relative;
    margin: 0;
    padding: 0;

    .header-layout {
      width: 100%;
    }

    .main-layout {
      display: block;
    }

    .sidebar-layout {
      position: absolute;
      bottom: 0;
      width: 100vw;
      padding: 0;
      height: auto;
      z-index: 1;
      top: unset;
      left: unset;
    }

    .content-layout {
      width: 100%;
      padding-bottom: 10em;
      overflow-y: scroll;
      background-color: $primaryBGColor;
      overflow-x: hidden;
    }
  }
}