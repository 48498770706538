@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

.header-main {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  width: 100%;
  height: 65px;
  background-color: #002c31;

  .left-header {
    width: 12%;
    h4 {
      font-size: 1.4rem;
      padding: 0;
      font-family: "Inter-Medium";
      color: #000;
      font-weight: 500;
      margin-bottom: 0;
    }
    .logo {
      cursor: pointer;
    }
    .logo img {
      width: 100%;
      height: 100%;
    }
  }

  .right-header {
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-left: 3rem;

    .image {
      img {
        width: 35px;
        height: 35px;
      }
      cursor: pointer;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.3rem;

      h4 {
        font-family: "Inter-Regular";
        font-size: 17px;
        margin-bottom: 0;
      }
    }
  }
  .left-links ul {
    list-style: none;
    display: flex;
    gap: 1rem;
    align-content: center;
    align-items: center;
    margin-bottom: 0;

    .create-agent {
      background-color: #d52429;
      color: #fff;
      border: none;
      display: flex;
      align-content: center;
      align-items: center;
      font-size: 15px;
      padding: 0.5rem 0.5rem;
      border-radius: 5px;
      gap: 0.5rem;
    }

    li {
      color: #fff;
    }
    li p {
      margin-bottom: 0;
    }
    .dropdown {
      display: flex;

      color: #fff;
    }
  }

  .right-header {
    width: 100%;
    .right-links {
      margin-left: auto;
      ul {
        margin-bottom: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2.5rem;
        color: #fff;
        li {
          list-style: none;
        }
      }
    }
  }

  .operation-class {
    cursor: pointer;
    background-color: #14444a;
    padding: 1.246rem;
  }
}
