.loader-main{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.loader-button {
    width: fit-content;
    margin: 0 auto;
    padding: 0;
}

.loader-main-loader{
 display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}